import * as React from "react"

// UI
import SectionCallout from "~components/SectionCallout"
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import Headline from "~ui/Headline"

// Media
import novoProductImg from "~images/customers/novo/novo-product.png"
import avatarImg from "~images/customers/novo/novo-testimonial.jpg"

const CustomerProduct = (): JSX.Element => {
  return (
    <>
      <SectionTwoCols className="mb-6 md:mb-24 lg:mb-32">
        <TwoColCard className="pl-6 2xl:pl-8 pr-12 2xl:pr-16 mb-8 lg:mb-0" data-sal="slide-up" noPadding>
          <Headline level={2} className="mb-6 md:mb-8">
            Building a <span className="text-blue-600">New Multi-Draw Product</span>
          </Headline>
          <p className="md:text-lg">
            Throughout senior leadership’s many years in merchant lending, they’ve learned a lot about what business
            owners actually need. As a result, Novo provides working capital on an as-needed basis, allowing their
            customers to repay when they’re able.
          </p>
        </TwoColCard>
        <TwoColCard className="bg-purple-900 overflow-hidden" data-sal="slide-up" data-sal-delay="150" noPadding>
          <img src={novoProductImg} alt="Novo Bank Card" data-sal="slide-up" data-sal-delay="300" />
        </TwoColCard>
      </SectionTwoCols>

      <SectionCallout color="green" className="mb-16 md:mb-24 lg:mb-32" narrow>
        <Headline level={5} className="pb-4 lg:pb-8 text-white">
          “Most small businesses don’t need 5+ year loans. Most of the time, what they need is working capital, and it’s
          episodic. It’s not like they can plan that one of their customers is going to be late paying them, yet they
          still have to pay their bills. It was understanding these needs that allowed us to create our somewhat unique
          [lending] structure.”
        </Headline>
        <div className="space-x-6">
          <div className="inline-block align-middle overflow-hidden rounded-full w-16 h-16 bg-blue-400 border border-white">
            <img src={avatarImg} alt="Kevin Phillips - EVP & GM, Novo" />
          </div>
          <div className="inline-block align-middle text-left text-white">
            <strong>Kevin Phillips</strong>
            <br /> EVP & GM, Novo
          </div>
        </div>
      </SectionCallout>
    </>
  )
}

export default CustomerProduct
