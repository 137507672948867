import * as React from "react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import Section from "~components/Section"

// UI
import SectionAlt from "~components/SectionAlt"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faChartMixed, faMoneyBills, faSidebar } from "@fortawesome/pro-light-svg-icons"

// Media
import Headline from "~ui/Headline"
import SectionThreeCols from "~components/SectionThreeCols"

const HowCard = ({
  animationDelay = 0,
  body,
  borderColor = "border-yellow-900",
  icon,
  title,
}: HowCardProps): JSX.Element => {
  return (
    <div
      className={`bg-black rounded-3xl p-8 items-start justify-start border ${borderColor}`}
      data-sal="slide-up"
      data-sal-delay={animationDelay}
    >
      <div className="rounded-full text-3xl lg:text-4xl text-black h-16 lg:h-24 w-16 lg:w-24 bg-white flex items-center justify-center mb-4 lg:mb-8">
        <Icon icon={icon} />
      </div>
      <Headline level={4} colorOverride="text-white" className="mb-6">
        {title}
      </Headline>
      <p className="text-gray-200">{body}</p>
    </div>
  )
}

const CustomerHow = (): JSX.Element => {
  return (
    <SectionAlt color="dark" className="dark relative overflow-hidden">
      <Section>
        <Headline level={2} data-sal="slide-up">
          <span className="text-yellow-300">How Novo Uses Canopy</span>
        </Headline>
        <p className="md:text-lg text-gray-300" data-sal="slide-up" data-sal-delay="200">
          Novo relies on Canopy as their ledger and system of record (SOR) for all transactions. Some of the specific
          features they use within their Canopy servicing account include:
        </p>
      </Section>
      <SectionThreeCols>
        <HowCard
          icon={faChartMixed}
          title="Managing Rich Data"
          body="DataDirect, a core feature of Canopy, ingests general ledger data for audit purposes, record keeping, reporting, and reconciliation for Novo banking customers."
          borderColor="border-green-900"
        />
        <HowCard
          icon={faSidebar}
          title="API & Webhooks"
          body="Using their own customer facing portal, Novo uses Canopy API and webhooks to populate information and monitor customer activity."
          animationDelay={150}
        />
        <HowCard
          icon={faMoneyBills}
          title="Multi-Advance"
          body="Novo also uses Canopy’s Multi-Advance product to give their customers more flexible options. This allows customers to take multiple draws against their Novo line of credit while synchronizing payments to a single date."
          borderColor="border-orange-900"
          animationDelay={300}
        />
      </SectionThreeCols>
      <Section>
        <p className="text-gray-500 text-xs mt-8 lg:mt-12">
          *Novo Platform Inc. (“Novo”) is a fintech, not a bank. Banking services provided by Middlesex Federal Savings,
          F.A. Member FDIC.Merchant Cash Advance products and services are offered by Novo Funding LLC (“Novo Funding”),
          a wholly owned subsidiary of Novo.
        </p>
      </Section>
    </SectionAlt>
  )
}

interface HowCardProps {
  animationDelay?: number
  body: string
  borderColor?: string
  icon: IconProp
  title: string
}

export default CustomerHow
