import * as React from "react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

// UI
import SectionAlt from "~components/SectionAlt"
import SectionMediaFull from "~components/SectionMediaFull"
import SectionThreeCols from "~components/SectionThreeCols"
import Headline from "~ui/Headline"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import {
  faArrowRightArrowLeft,
  faCreditCardFront,
  faHandHoldingDollar,
  faMoneyBill,
  faSackDollar,
} from "@fortawesome/pro-light-svg-icons"

const ThreeColCard = ({
  animationDelay = 0,
  borderColor = "border-gray-400",
  icon,
  title,
}: ThreeColCardProps): JSX.Element => {
  return (
    <div
      className={`bg-black rounded-3xl p-8 items-start justify-start border ${borderColor}`}
      data-sal="slide-up"
      data-sal-delay={animationDelay}
    >
      <div className="rounded-full text-3xl lg:text-4xl text-black h-16 lg:h-24 w-16 lg:w-24 bg-white flex items-center justify-center mb-4 lg:mb-8">
        <Icon icon={icon} />
      </div>
      <Headline level={5} colorOverride="text-white">
        {title}
      </Headline>
    </div>
  )
}

const CustomerOverview = (): JSX.Element => {
  return (
    <SectionAlt color="dark" className="dark mb-16 md:mb-20 lg:mb-32">
      <SectionMediaFull
        title={<span className="text-yellow-300">How Novo Got its Start</span>}
        subTitle={
          <span className="text-gray-300">
            Novo was founded in 2016 with a mission to empower business owners with their financial data through a
            modern approach to checking accounts. Built for today’s business owners, their roots lie in helping
            freelancers, startup founders, and small business owners open checking accounts within minutes, securely and
            easily without minimum balance requirements.
          </span>
        }
        video={
          <iframe
            src="https://www.youtube.com/embed/EH8l7QTO3zI?rel=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
            allowFullScreen
          ></iframe>
        }
        className="mb-24"
      >
        <p className="lg:text-lg lg:leading-relaxed text-gray-300">
          “We focus on making the lives of our small business customers easier. These people are not financiers, they’re
          not bankers. They’re ballet teachers, they’re auto mechanics, they’re lawyers. Our goal is to reduce the
          amount of time they have to spend managing the financial affairs of their business.”
        </p>
      </SectionMediaFull>
      <SectionThreeCols
        title={
          <span className="text-yellow-300">
            Since its inception, Novo banking customers have been able to simplify their business finances with
            capabilities & features like:
          </span>
        }
        level={4}
      >
        <ThreeColCard icon={faMoneyBill} title="Depositing and sending checks via the mobile app" />
        <ThreeColCard icon={faHandHoldingDollar} title="Real-time insights and an overview of their finances" />
        <ThreeColCard icon={faCreditCardFront} title="A virtual debit card to make payments right away" />
        <ThreeColCard icon={faArrowRightArrowLeft} title="Unlimited transactions and fast, easy transfers" />
        <ThreeColCard icon={faSackDollar} title="Near instant access to Stripe payments through Novo Boost" />
      </SectionThreeCols>
    </SectionAlt>
  )
}

interface ThreeColCardProps {
  animationDelay?: number
  borderColor?: string
  icon: IconProp
  title: string
}

export default CustomerOverview
