import * as React from "react"

// UI
import SectionMediaFull from "~components/SectionMediaFull"
import SectionCallout from "~components/SectionCallout"
import Headline from "~ui/Headline"

// Media
import avatarImg from "~images/customers/novo/novo-testimonial.jpg"

const CustomerWhy = (): JSX.Element => {
  return (
    <div className="py-16 lg:py-20 xl:py-32">
      <SectionMediaFull
        title={
          <>
            Why Novo Chose Canopy as its
            <br className="hidden md:block" /> <span className="text-blue-600">Credit Portfolio Servicing Partner</span>
          </>
        }
        subTitle={
          <>
            After seeing a number of lending core providers over his 15 years in business lending, Novo’s EVP of Credit
            approached Canopy once the time came to select its servicing partner.
            <br />
            <br />
            As one of the newer financial technology providers in the lending industry, Canopy became one of the leading
            candidates for what Novo was trying to build next.
            <br />
            <br />
            They went through a deep dive process in order to vet a platform that could support their unique product
            offering. Furthermore, they were looking for a supportive team that they wanted to partner with, which is
            what ultimately drove their decision to work with Canopy.
          </>
        }
        video={
          <iframe
            src="https://www.youtube.com/embed/VAsDlVLDXMI?rel=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        }
        className="mb-6 lg:mb-8"
      />

      <SectionCallout color="primary">
        <Headline level={5} className="pb-4 lg:pb-8 text-yellow-300">
          “We worked with Canopy to configure our solution and make it publicly available. They provide us with the
          information so we can service our portfolio, as well provide that information to our customers so they can
          understand their financial situation vis-à-vis what they have drawn.”
        </Headline>
        <div className="space-x-6">
          <div className="inline-block align-middle overflow-hidden rounded-full w-16 h-16 bg-blue-400 border border-white">
            <img src={avatarImg} alt="Kevin Phillips - EVP & GM, Novo" />
          </div>
          <div className="inline-block align-middle text-left text-white">
            <strong>Kevin Phillips</strong>
            <br /> EVP & GM, Novo
          </div>
        </div>
      </SectionCallout>
    </div>
  )
}

export default CustomerWhy
