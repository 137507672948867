import * as React from "react"
import Section from "~components/Section"

// UI
import SectionAlt from "~components/SectionAlt"
import Headline from "~ui/Headline"

const CustomerChallenges = (): JSX.Element => {
  return (
    <SectionAlt color="dark" className="dark">
      <Section>
        <Headline level={2}>
          <span className="text-white">What Are the Risks of Extending Credit to </span>
          <span className="text-yellow-300">Small Businesses?</span>
        </Headline>
        <p className="md:text-lg leading-normal text-gray-200" data-sal="slide-up" data-sal-delay="200">
          Providing credit to small businesses is risky, which is why most banks don’t offer loans and credit to smaller
          businesses, such as the ones Novo operates with. Unlike traditional banks, though, Novo takes a different
          approach by pricing in the risk.
          <br />
          <br />
          For instance, if a customer goes delinquent, Novo has the information and data to direct their decision-making
          process in order to do the right thing for both the customer and their own organization, adding a layer of
          built-in safety with their product.
        </p>
      </Section>
    </SectionAlt>
  )
}

export default CustomerChallenges
