import React from "react"

// UI
import Layout from "~components/layout"
import SEO from "~components/seo"
import CustomerHero from "~content/customers/detail/Hero"
import CustomerIntro from "~content/customers/detail/Intro"
import CustomerOverview from "~content/customers/detail/Overview"
import CustomerProduct from "~content/customers/detail/Product"
import CustomerChallenges from "~content/customers/detail/Challenges"
import CustomerWhy from "~content/customers/detail/Why"
import CustomerHow from "~content/customers/detail/How"

// Media
import imgHeroNovo from "~images/customers/novo/novo-hero.jpg"

const CustomerNovo: React.FC = () => {
  return (
    <Layout>
      <SEO
        canonicalTag="https://canopyservicing.com/customers/novo"
        description="Canopy Servicing is an API-first platform that is enabling the next generation of credit and lending products."
        title="Novo Case Study | Canopy Servicing"
      />

      <CustomerHero
        title={
          <>
            How Novo Built B2B
            <br className="lg:block" /> Multi-Credit on Canopy
          </>
        }
        subtitle="As a flexible, modern banking tool designed to help small businesses grow, the team at Novo is no stranger to taking a traditional banking concept and digitizing it from end-to-end."
        productTags={["Ledger", "DataDirect", "LoanLab", "Webhooks"]}
        teamTags={["Operations", "Product", "Credit & Analytics", "Engineering", "Legal & Compliance"]}
        img={imgHeroNovo}
        imgAlt="Novo Case Study Hero Image"
      />

      <CustomerIntro
        title="Launching B2B Multi-Credit"
        body={
          <>
            A trusted partner for over 200,000 small businesses throughout the United States and recipient of the 2023
            Forbes Fintech 50, Novo provides faster cash flow and clear financial insights without monthly fees or
            minimum balance requirements. The Novo platform also has connections to thousands of business apps, such as
            Stripe, Square, Shopify, and Gusto.
            <br />
            <br />
            In October 2022, they began piloting a new hybrid, multi-draw product offering, using Canopy as their credit
            portfolio servicing platform.
          </>
        }
      />

      <CustomerOverview />
      <CustomerProduct />
      <CustomerChallenges />
      <CustomerWhy />
      <CustomerHow />
    </Layout>
  )
}

export default CustomerNovo
